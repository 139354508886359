@font-face {
	font-family: 'Gotham';
	src: url('/mm/fonts/Gotham-Light_Web.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url('/mm/fonts/Gotham-Book_Web.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url('/mm/fonts/Gotham-Medium_Web.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url('/mm/fonts/Gotham-Bold_Web.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url('/mm/fonts/Gotham-LightItalic_Web.woff2') format('woff2');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url('/mm/fonts/Gotham-BookItalic_Web.woff2') format('woff2');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}
